import React, { useState } from "react";
import AWS from "aws-sdk";
import {
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  Snackbar,
  LinearProgress, // Import LinearProgress for the loading bar
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

// Configure AWS SDK
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const ses = new AWS.SES({ apiVersion: "2010-12-01" });

const JobForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    position: "",
    linkedinURL: "",
    resume: null,
    resumePreview: "",
    roleType: "",
    additionalInfo: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0); // Track the progress

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
  
    if (file && file.size > 15 * 1024 * 1024) {
      setSnackbarMessage("File size exceeds 15 MB limit");
      setOpenSnackbar(true);
      return;
    }
  
    if (file) {
      setLoading(true);
      setUploadProgress(0); // Reset progress
      setFormData((prevData) => ({
        ...prevData,
        resume: null, // Reset existing resume when a new file is uploaded
        resumePreview: "", // Reset existing preview
      }));
  
      const reader = new FileReader();
  
      reader.onprogress = (event) => {
        if (event.total > 0) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress); // Update progress
        }
      };
  
      reader.onloadend = () => {
        setLoading(false);
        setFormData((prevData) => ({
          ...prevData,
          resume: reader.result.split(",")[1], // Extract base64 data
          resumePreview: URL.createObjectURL(file), // Preview URL
        }));
      };
  
      reader.readAsDataURL(file);
    }
  };
  

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);

    const {
      fullName,
      email,
      phone,
      position,
      linkedinURL,
      roleType,
      additionalInfo,
      resume,
    } = formData;

    if (!resume) {
      setMessage("Please upload a resume.");
      return;
    }

    const rawEmail = `From: ${process.env.REACT_APP_SES_FROM_EMAIL}
To: ${process.env.REACT_APP_SES_TO_EMAIL}
Subject: New Job Application Submission
MIME-Version: 1.0
Content-Type: multipart/mixed; boundary="boundary123"

--boundary123
Content-Type: text/plain; charset="UTF-8"

Full Name: ${fullName}
Email: ${email}
Phone: ${phone}
Position: ${position}
LinkedIn URL: ${linkedinURL}
Role Type: ${roleType}
Additional Information: ${additionalInfo}

--boundary123
Content-Type: application/pdf; name="resume.pdf"
Content-Disposition: attachment; filename="resume.pdf"
Content-Transfer-Encoding: base64

${resume}
--boundary123--`;

    const params = {
      RawMessage: { Data: rawEmail },
      Source: process.env.REACT_APP_SES_FROM_EMAIL,
      Destinations: [process.env.REACT_APP_SES_TO_EMAIL],
    };

    ses.sendRawEmail(params, (err, data) => {
      if (err) {
        console.error("Error sending email:", err);
        setMessage("Failed to send email.");
      } else {
        console.log("Email sent successfully:", data);
        setMessage("Email sent successfully!");
      }
    });
  };

  return (
    <div style={{ backgroundColor: "#f5ffff", padding: "32px" }}>
      <div
        className="form-container"
        style={{
          maxWidth: "900px",
          margin: "auto",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Typography variant="h4" component="h1" fontWeight="bold" mb={4}>
            JOIN US
          </Typography>
          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <TextField
            label="Phone"
            variant="outlined"
            fullWidth
            margin="normal"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <div style={{ width: "300px", margin: "16px 0" }}>
            <label
              htmlFor="position"
              style={{ display: "block", fontWeight: "bold" }}
            >
              Position
            </label>
            <select
              name="position"
              value={formData.position}
              onChange={handleChange}
              required
              id="position"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            >
              <option value="">Select Position</option> {/* Add this line */}
              <option value="Embedded Hardware Engineer">
                Embedded Hardware Engineer
              </option>
              <option value="Embedded Software Engineer">
                Embedded Software Engineer
              </option>
              <option value="Mechanical Engineer">Mechanical Engineer</option>
              <option value="Thermal Systems Engineer">Thermal Systems Engineer</option>
            </select>
          </div>

          <TextField
            label="LinkedIn URL"
            variant="outlined"
            fullWidth
            margin="normal"
            name="linkedinURL"
            value={formData.linkedinURL}
            onChange={handleChange}
            required
          />

          {/* File Upload */}
          <div>
            <label htmlFor="resume-upload">
              <Button
                variant="outlined"
                component="span"
                sx={{ marginTop: "16px" }}
              >
                Upload Resume (up to 15 MB)
              </Button>
            </label>
            <input
              type="file"
              id="resume-upload"
              name="resume"
              accept="application/pdf"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {loading && (
              <CircularProgress size={24} sx={{ marginLeft: "16px" }} />
            )}
            {/* Display progress bar */}
            {loading && (
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                sx={{ marginTop: "16px" }}
              />
            )}
          </div>

          {formData.resume && (
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" sx={{ marginRight: "12px" }}>
                {formData.resume.name}
              </Typography>
              <Button
                variant="contained"
                color="success"
                href={formData.resumePreview}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </Button>
              
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 4 }}
          >
            Submit
          </Button>
        </form>

        {message && (
          <Alert style={{ marginTop: "16px" }}>
          {message}
        </Alert>
        
        )}

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </div>
    </div>
  );
};

export default JobForm;
