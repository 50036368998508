import React from 'react'
import { Typography, Container } from "@mui/material";

const FoundingStory = () => {
  return (
    <div>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{ padding: "5%" }}>
      <Typography
        variant="h3"
        align="center"
        color="primary"
        sx={{
          fontWeight: "bold",
          fontSize: "2.5rem",
          marginBottom: "1.5rem",
          lineHeight: "1.4",
        }}
      >
        Founding Story: Our Journey from Dreams to Vision
      </Typography>

      <Typography
        variant="body1"
        align="center"
        sx={{
          fontSize: "1.2rem",
          fontStyle: "italic",
          lineHeight: "1.6",
          marginBottom: "1rem",
        }}
      >
        Three friends, one garage, building cars, engineering minds – it started how it usually does – trying to challenge the norm.
      </Typography>


      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.7",
          marginBottom: "1.5rem",
        }}
      >
        We started right after our graduation, unsure of exactly what we would be building, but one thing was pretty clear —we wanted to be at the intersection of top-notch product building in India, solving tough challenges, and driving sustainability.
      </Typography>

      <Typography
        variant="body2"
        align="left"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.6",
          marginBottom: "1.5rem",

        }}
      >
        Our search for crucial problems led us to explore diverse areas:
      </Typography>

      <Typography
        variant="body2"
        align="left"
        sx={{
          fontSize: "1.2rem",
          marginBottom: "1.5rem",
          lineHeight: "1.7",
          marginLeft: "2rem",
        }}
      >
        • Grid-level storage solutions for renewable energy integration<br/>
        • Vanadium Redox Flow batteries<br/>
        • Waste Heat Recovery systems<br/>
        • Cold Storage solutions… and many more.<br/>
      </Typography>
      {/* <Typography
        variant="body2"
        align="left"
        sx={{
          fontSize: "1.2rem",
          marginBottom: "1.5rem",

          marginLeft: "2rem",
        }}
      >
       
      </Typography>
      <Typography
        variant="body2"
        align="left"
        sx={{
          fontSize: "1.2rem",
          marginBottom: "1.5rem",

          marginLeft: "2rem",
        }}
      >
       
      </Typography>
      <Typography
        variant="body2"
        align="left"
        sx={{
          fontSize: "1.2rem",
          marginBottom: "1.5rem",

          marginLeft: "2rem",
        }}
      >
       
      </Typography> */}

      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.7",
          marginBottom: "1.5rem",
        }}
      >
        We realized that cooling directly opposes the second law of thermodynamics, making it an energy-intensive process—significantly more than transportation itself.      This insight led us straight into the heart of Cold Chain Logistics, a space where logistics companies were struggling with energy inefficiencies and operational challenges.
      </Typography>

      <Typography
        variant="body1"
        align="center"
        sx={{
          fontSize: "1.2rem",

          lineHeight: "1.7",
          marginBottom: "1.5rem",
        }}
      >
        With this understanding, we created prototypes, validated our business case, and officially incorporated our company.
      </Typography>

      

      <Typography
        variant="h5"
        align="center"
        sx={{
          fontSize: "1.2rem",
          fontStyle: "italic",
          color: "primary.main",
        }}
      >
        Join us in our journey from Vision to Real Impact.
      </Typography>
      </Container>
    </div>
  )
}

export default FoundingStory