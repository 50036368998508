import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import "./jobroleinfo.css";

const RoleTh = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{ padding: "5%" }}>
      <div className="rolecontainer mx-auto p-8 rounded-lg max-w-3xl">
        <a
          href="/jobs"
          className="text-gray-600 hover:text-orange-600 transition-colors duration-300 mb-4 block"
        >
          &lt; Back
        </a>

        <div className="mb-8">
          <Typography
            variant="h4"
            component="h1"
            color="textPrimary"
            fontWeight="bold"
          >
            Thermal System Engineer
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Chennai, Tamil Nadu, India
          </Typography>
        </div>
        <div className="typebox">
          <Box display="flex" justifyContent="space-between" mb={4}>
            <div className="custom-container">
              <Typography variant="body1" align="left" color="textPrimary">
                <strong>Job Type:</strong> Full Time
              </Typography>

              <Typography variant="body1" align="left" color="textPrimary">
                <strong>Availability:</strong> Immediate
              </Typography>
            </div>
          </Box>
        </div>

        <section className="mb-12 text-left">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            About the company:
          </Typography>
          <Typography
            variant="body1"
            align="justify"
            className="mt-4 text-justify"
            sx={{ marginBottom: 2 }}
          >
            Yotuh Energy's mission toward sustainable and ultra energy-efficient
            cooling has just begun, starting with the often-overlooked yet
            deeply impactful sector of cold chain logistics.
            <br />
            <br />
            We engineer electric refrigeration units for cold logistics trucks.
            Our product ensures the correct temperature is maintained in the
            cargo container of truck during the transit. By doing so we provide
            more economical, reliable and greener way to transport perishables
            like milk, curd, paneer, meat, seafood, icecreams, frozen foods,
            fruits veggies, as well as vaccines and pharmaceuticals.With such a
            massive population to feed, our product solves for big missing link
            in cold chain logistics.
          </Typography>
        </section>

        <section className="mb-12 text-left">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            About the Role:
          </Typography>
          <Typography
            variant="body1"
            align="justify"
            className="mt-4 text-justify"
            sx={{ marginBottom: 2 }}
          >
            Yotuh Energy is the first and only company company in India to build
            electric refrigeration units that are enabling upcoming Electric
            Vehicles and existing Small Commercial Vehicles to be able to do
            cold logistics <br />
            <br />
            As Thermals Systems Engineer you will be responsible-
            <br />
            <br />
            <ul
              className="list-disc list-inside mt-4 text-lg "
              style={{ listStyleType: "disk" }}
            >
              <Typography variant="body1" align="justify" mb={4} ml={4}>
                <li>
                  Design, development, and optimization of cooling system.
                </li>
                <li>
                  Build deep understanding and correlations between various
                  subsystems considering heat transfer rates, insulation
                  characteristics & power consumptions.
                </li>
                <li>
                  Model & Test various solutions for tradeoffs between
                  performance, reefer run times, system cost and battery size.
                </li>
              </Typography>
            </ul>
            Role offers a very unique and tough enduring opportunity for
            engineers to design, build, test and scaelup a unique product.
          </Typography>
        </section>

        <section className="mb-12 text-left">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            mt={4}
            sx={{ marginBottom: 2 }}
          >
            Responsibilities:
          </Typography>
          <Typography
            variant="h6"
            align="justify"
            className="mt-4 text-justify"
            sx={{ marginBottom: 2 }}
          >
            Refrigeration System Design:
          </Typography>

          <ul
            className="list-disc list-inside mt-4 text-lg "
            style={{ listStyleType: "disk" }}
          >
            <Typography variant="body1" align="justify" mb={4} ml={2}>
              <li>
                Design and development of refrigeration system, including
                component selection, compressors, piping designs, etc
              </li>
              <li>
                Perform high level system tradeoffs between different thermal
                design solutions to optimize for performance, system cost and
                battery size.
              </li>
              <li>
                Component Selections, like Expansions Valves, Compressors,
                Receiver Driers, Oil Seperators, Fans, Pressure Reduction
                Valves, etc
              </li>
              <li>
                Design and optimize heat exchangers, specifically evaporators
                and condensers, with a focus on refrigerant circuiting and
                thermal optimization for both refrigerant and air circuits.
              </li>
            </Typography>
          </ul>

          <Typography
            variant="h6"
            align="justify"
            className="mt-4 text-justify"
            sx={{ marginBottom: 2 }}
          >
            Thermal Modeling:
          </Typography>

          <ul
            className="list-disc list-inside mt-4 text-lg "
            style={{ listStyleType: "disk" }}
          >
            <Typography variant="body1" align="justify" mb={4} ml={2}>
              <li>
                Develop and implement first-principles-based thermal models for
                heat transfer.
              </li>
              <li>
                Create models to aid mechanical design teams in component
                development or gain insight into system level thermal tradeoffs.
              </li>
              <li>
                Conduct sensitivity analyses, assess design feasibility and
                perform tradeoff studies using the various models.
              </li>
            </Typography>
          </ul>

          <Typography
            variant="h6"
            align="justify"
            className="mt-4 text-justify"            
            sx={{ marginBottom: 2 }}
          >
            Testings:
          </Typography>

          <ul
            className="list-disc list-inside mt-4 text-lg "
            style={{ listStyleType: "disk" }}
          >
            <Typography variant="body1" align="justify" mb={4} ml={2}>
              <li>
                Define & execute prototype builds and testing them to validate
                thermal designs.
              </li>
              <li>Perform component and system level DVTPs.</li>
              <li>
                Possess strong communication skills to convey findings and
                collaborate with team members.
              </li>
              <li>Support quick on-road troubleshooting of Reefer Units.</li>
            </Typography>
          </ul>
        </section>

        <section className="mb-12">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            Requirements:
          </Typography>
          <ul
            className="list-disc list-inside mt-4 text-lg "
            style={{ listStyleType: "disk" }}
          >
            <Typography variant="body1" align="justify" mb={4} ml={2}>
              <li>
                Degree in Thermal/Mechanical Engineering, or equivalent
                experience.
              </li>
              <li>
                Deep knowledge of heat transfer principles, fluid dynamics, and
                HVAC systems.
              </li>
              <li>
                Proficiency in numerical simulation of heat and fluid flow.
              </li>
              <li>
                Fluency in the use of analytical simulation tools for thermal
                analysis, including Matlab/Simulink and CFD software.
              </li>
              <li>
                Strong fundamentals in refrigeration compression cycle modeling
                and operation .
              </li>
              <li>Knowledge in Geometric Dimensioning & Tolerances.</li>
              <li>
                Practical experience in the design and testing of thermal
                systems, including refrigerant loops, heat exchangers, fans,
                EXVs, etc
              </li>
            </Typography>
          </ul>
        </section>

        <section className="mb-12">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            align="justify"
            sx={{ marginBottom: 2 }}
          >
            Hiring Process:
          </Typography>
          <Typography variant="body1" className="mt-4" align="justify">
            If you have been shortlisted, you will receive confirmation mail
            within 3 days of application. There will be a Technical Interview
            and followed another Founder’s Interview. There could be engineering
            challenge to be submitted.
          </Typography>
        </section>
        <div>
          <a href="/jobForm">
            <Button
              variant="contained"
              color="warning"
              sx={{
                paddingX: 4,
                paddingY: 2,
                borderRadius: 2,
                fontSize: "1rem",
                textTransform: "none",
                marginTop: 5,
              }}
            >
              Apply Now
            </Button>
          </a>
        </div>
      </div>
    </Container>
  );
};

export default RoleTh;
