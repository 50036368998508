import React from "react";
import { Amplify } from "aws-amplify";
import { awsExports, amplifyConfig } from "./aws-exports";
import { BrowserRouter as Router, Route, Routes, Form } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
// import Login from "./components/login";
import Layout from "./components/Layout";
// import Home from "./components/Home";
import "@aws-amplify/ui-react/styles.css";
import YotuhHome from "./components/YotuhHome";
// import RequireAuth from "./components/RequireAuth";
// import Product from "./components/Product";
import Impact from "./components/Impact";
import AboutUs from "./components/AboutUs";
import Productnew from "./components/Productnew";
import Iotdashboard from "./components/Iotdashboard";
import Order from "./components/Order";
import Product from "./components/Product";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import Career from "./components/Career";
import Culture from "./components/Culture";
import FoundingStory from "./components/FoundingStory";
import RoleEmbH from "./components/RoleEmbH";
import RoleEmbS from "./components/RoleEmbS";
import RoleMechE from "./components/RoleMechE";
import RoleTh from "./components/RoleTh";
import JobForm from "./components/JobForm";

Amplify.configure(amplifyConfig);

const App = () => {


  return (
    <Authenticator.Provider>
      <Router>
      <ScrollToTop/>
        <Layout>
          <Routes>
            {/* <Route path="" element={<RequireAuth />}>
              
            </Route> */}
          {/* <Route path="/" element={<Home />} /> */}
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/" element={<YotuhHome />} />
            <Route path="/Product" element={<Product />} />
            <Route path="/Productnew" element={<Productnew />} />

            <Route path="/Impact" element={<Impact />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/iotd" element={<Iotdashboard/>} />
            <Route path="/Order" element={<Order/>} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
            <Route path="/Jobs" element={<Career/>} />
            <Route path="/Culture" element={<Culture/>} />
            <Route path="/FoundingStory" element={<FoundingStory/>} />
            <Route path="/RoleEmbH" element={<RoleEmbH/>} />
            <Route path="/RoleEmbS" element={<RoleEmbS/>} />
            <Route path="/RoleMechE" element={<RoleMechE/>} />
            <Route path="/RoleTh" element={<RoleTh/>} />
            <Route path="/JobForm" element={<JobForm/>} />

          </Routes>
        </Layout>
      </Router>
    </Authenticator.Provider>
  );
};

export default App;
