import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import "./jobroleinfo.css";
const RoleEmbS = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{ padding: "5%" }}>
      <div className="rolecontainer mx-auto p-8 rounded-lg max-w-3xl">
        <a
          href="/jobs"
          className="text-gray-600 hover:text-orange-600 transition-colors duration-300 mb-4 block"
        >
          &lt; Back
        </a>

        <div className="mb-8">
          <Typography
            variant="h4"
            component="h1"
            color="textPrimary"
            fontWeight="bold"
          >
            Embedded Software Engineer
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Chennai, Tamil Nadu, India
          </Typography>
        </div>
        <div className="typebox">
          <Box display="flex" justifyContent="space-between" mb={4}>
            <div className="custom-container">
              <Typography variant="body1" align="left" color="textPrimary">
                <strong>Job Type:</strong> Full Time
              </Typography>

              <Typography variant="body1" align="left" color="textPrimary">
                <strong>Availability:</strong> Immediate
              </Typography>
            </div>
          </Box>
        </div>

        <section className="mb-12 text-left">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            About the Company:
          </Typography>
          <Typography
            variant="body1"
            align="justify"
            className="mt-4 text-justify"
            sx={{ marginBottom: 2 }}
          >
            Yotuh Energy's mission toward sustainable and ultra energy-efficient
            cooling has just begun, starting with the often-overlooked
            yet deeply impactful sector of cold chain logistics.
            <br />
            <br />
            We engineer electric refrigeration units for cold logistics trucks.
            Our product ensures the correct temperature is maintained in the
            cargo container of truck during the transit. By doing so we provide
            more economical, reliable and greener way to transport perishables
            like milk, curd, paneer, meat, seafood, icecreams, frozen foods,
            fruits veggies, as well as vaccines and pharmaceuticals.With such a
            massive population to feed, our product solves for big missing link
            in cold chain logistics.
            <br />
          </Typography>
        </section>

        <section className="mb-12 text-left">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            About the Role:
          </Typography>
          <Typography
            variant="body1"
            align="justify"
            className="mt-4 text-justify"
            sx={{ marginBottom: 2 }}
          >
            Yotuh Energy is the first and only company company in India to build
            electric refrigeration units that are enabling upcoming Electric
            Vehicles and existing Small Commercial Vehicles to be able to do
            cold logistics.
            <br />
            <br />
            As embedded software engineer you will be:
            <br />
            <br />
            <ul className="list-disc list-inside mt-4 text-lg " style={{ listStyleType: "disk" }}>
              <Typography variant="body1" align="justify" mb={4} ml={4}>
                <li>
                  Designing and maintain firmware of our product involving
                  Control Unit, HMI Unit, Power Distribution Unit, etc.
                </li>
                <li>
                  Work with embedded hardware, controls, refrigeration teams in
                  understanding how overall product will behave and design
                  accordingly.
                </li>
                <li>
                  Planning System Level engineering goals, timelines, and
                  executing on it.
                </li>
              </Typography>
            </ul>
            Role offers a very unique and tough enduring opportunity for
            engineers to design, build, test and scaelup a unique product.
            Building a refrigeration unit that is carrying critical products
            giving no time till they will perish- demands to have one of the
            most reliable electronics ever built.
          </Typography>
        </section>

        <section className="mb-12 text-left">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            mt={4}
            sx={{ marginBottom: 2 }}
          >
            Responsibilities:
          </Typography>
          <ul className="list-disc list-inside mt-4 text-lg " style={{ listStyleType: "disk" }}>
            <Typography variant="body1" align="justify" mb={4} ml={2}>
              <li>
                Understand expected behavior of product by working closely with
                subsystems & teams like - Refrigeration, Embedded Hardware,
                HMI,Users, etc.
              </li>
              <li>
                Conceptualize and design building blocks and communication lines
                by translating product behavior into firmware design.
              </li>
              <li>
                Develop and implement error, warning, and preventive alerts for
                critical parameters and risks, such as refrigeration health and
                operational errors. (fault monitoring system/fault management
                system)
              </li>
              <li>
                Understand and implement safety and reliability measures for
                firmware, including Failure Modes and Effects Analysis (FMEA),
                as well as conduct code and risk reviews.
              </li>
              <li>
                Interacting with the operations team regularly for analyzing
                issues.
              </li>
              <li>
                Lead the transition of taking code to production, including
                design, development, validation and field deployments.{" "}
              </li>
              <li>
                SIL testings/respobsible for test bench for code testing.{" "}
              </li>
              <li>
                Developing Technical documents and manuals to accurately
                represent- the application design, debugging and diagnostic
                standard operating procedures (SOPs).
              </li>
            </Typography>
          </ul>
        </section>

        <section className="mb-12">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            Requirements:
          </Typography>
          <ul className="list-disc list-inside mt-4 text-lg " style={{ listStyleType: "disk" }}>
            <Typography variant="body1" align="justify" mb={4} ml={2}>
              <li>
                Experience with ARM-based microcontroller software development,
                preferably on STM32 platforms.
              </li>
              <li>
                Practical hands-on experience with communication protocols such
                as SPI, I2C, UART, CAN, and USB.
              </li>
              <li>Proficiency in using IDEs like STM Cube.</li>
              <li>Familiarity with embedded C, C++, and optional Python.</li>
              <li>
                Strong Understanding of basic coding algorithms and skills in
                code optimization.
              </li>
            </Typography>
          </ul>
        </section>

        <section className="mb-12">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            align="justify"
            sx={{ marginBottom: 2 }}
          >
            Hiring Process:
          </Typography>
          <Typography variant="body1" className="mt-4" align="justify">
            If you have been shortlisted, you will receive confirmation mail
            within 3 days of application. There will be a Technical Interview
            and followed another Founder’s Interview. There could be engineering
            challenge to be submitted.
          </Typography>
        </section>
        <div>
          <a href="/jobForm">
            <Button
              variant="contained"
              color="warning"
              sx={{
                paddingX: 4,
                paddingY: 2,
                borderRadius: 2,
                fontSize: "1rem",
                textTransform: "none",
                marginTop: 5,
              }}
            >
              Apply Now
            </Button>
          </a>
        </div>
      </div>
    </Container>
  );
};

export default RoleEmbS;
