import React from "react";
import {
  Box,
  Container,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PrinciplesSection from "./PrinciplesSection";
import './career.css';

const jobData = [
  {
    title: "Power Electronics Hardware Engineer",
    location: "Bengaluru, Karnataka, India",
    description:
      "Responsible for designing and testing power electronic circuits. Requires knowledge of DC-DC converters, inverters, and embedded systems.",
  },
  {
    title: "Firmware Engineer",
    location: "Bengaluru, Karnataka, India",
    description:
      "Develop and debug firmware for embedded systems. Proficiency in C/C++ and RTOS is required.",
  },
];

const jobTypes = ["Full-Time", "Part-Time", "Internship"];
const workspaces = ["Onsite", "Remote", "Hybrid"];

const Career = () => {
  const [jobType, setJobType] = React.useState(null);
  const [workspace, setWorkspace] = React.useState(null);
  const [selectedJob, setSelectedJob] = React.useState(null);

  const handleSearch = () => {
    console.log("Search clicked with:", { jobType, workspace });
  };

  const handleViewJob = (job) => {
    setSelectedJob(job);
  };

  const handleCloseDialog = () => {
    setSelectedJob(null);
  };

  return (
    <div  className='ContainerC' style={{ display: "flex"}}>
      <div className="imageCCon">
        <img
          src="/careerImg/truckBlack.jpg"
          alt="Logo"
          style={{
            width: "100%",
            height: "100%",
            marginRight: "1rem",
          }}
        />
      </div>
      <div className="imageCCon2">
        <img
          src="/careerImg/truckQuote.jpg"
          alt="Logo"
          style={{
            width: "100%",
            height: "100%",
            marginRight: "1rem",
          }}
        />
      </div>
      <div>
        <Container
          maxWidth="lg"
          sx={{  mb: 4 }}
          style={{ padding: "5%" }}
        >
          <div className="headtextcareers"><Typography
            variant="h4"
            gutterBottom
            align="center"
            style={{ padding: "2%", marginBottom: "3%" }}
            
          >
            Inviting all cool engineers to build the next generation of cooling
            solutions
          </Typography></div>
          <Typography
            variant="body1"
            align="left"
            paragraph
            sx={{ fontSize: "1.1rem" }}
          >
            We value high inner drive. <br /> Before we proceed, we want you to
            get to know us better. Understand what we do, why we do it, and how
            we do it. This could give you a glimpse into how we wish to build
            our team.
            <br />
          </Typography>

          <Typography
            variant="body1"
            align="left"
            color="primary"
            sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            Founding Story :{" "}
            <Typography
              variant="body1"
              align="left"
              color="black"
              paragraph
              sx={{ fontSize: "1.1rem" }}
              mt={2}
            >
              {" "}
              Three friends, one garage, building cars, engineering minds - it
              started how it usually does- trying to challenge the norm. This is
              where the ordinary ends and the extraordinary begins.
              <a
                href="/foundingStory"
                style={{
                  textDecoration: "none",
                  color: "blue",
                  fontSize: "0.8rem",
                }}
              >
                 Read More
              </a>
            </Typography>
          </Typography>

          <Typography
            variant="body1"
            align="left"
            color="primary"
            sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            Culture
          </Typography>

          <Typography
            variant="body1"
            align="justify"
            sx={{
              fontSize: "1.1rem",
              lineHeight: "1.7",
              marginBottom: "2rem",
            }}
            mt={2}
          >
            Building in an early-stage startup is an immensely challenging yet
            rewarding journey. <br />
            Being present at the epicenter of a change—right where the future is
            being shaped.
          </Typography>

          <Typography
            variant="body1"
            align="justify"
            sx={{
              fontSize: "1.1rem",
              lineHeight: "1.7",
              marginBottom: "2rem",
            }}
          >
            Turning the improbable into reality takes a lot of hustle,
            dedication, and determination towards the goal. The key to success
            lies in surrounding & resonating with people who share common
            values—because that’s how collaborative decision-making and swift
            innovation happen.
          </Typography>

          <Typography
            align="justify"
            sx={{
              fontSize: "1.1rem",
              marginBottom: "2rem",
            }}
          >
            As we grow Bigger & Better—our culture may evolve, but the core
            essence will always be grounded in these principles:
          </Typography>

          <PrinciplesSection />
          <Typography
            variant="body1"
            align="justify"
            sx={{
              fontSize: "1.1rem",
              lineHeight: "1.7",
              marginBottom: "2rem",
            }}
          >
            Do these values resonate with you? If yes, you will feel at home
            here. We're always looking for passionate and curious team members
            who share our excitement to cool this warming planet—together.
          </Typography>

          <Typography variant="h4" paragraph align="left" marginTop="5%">
          We've got big plans - and they include YOU!
          </Typography>
          

          <Typography variant="h5" paragraph align="left" marginTop="5%">
            Open Positions:
          </Typography>

          <Typography
            variant="body1"
            align="left"
            color="primary"
            sx={{ fontSize: "1.1rem", fontWeight: "bold" }}
          >
            <a
              href="/roleEmbH"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Embedded Hardware Engineer
            </a>

            <br />
            <a
              href="/roleEmbS"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Embedded Software Engineer
            </a>

            <br />
            <a
              href="/roleMechE"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Mechanical Engineer
            </a>
            <br />
            <a href="/roleTh" style={{ textDecoration: "none", color: "inherit" }}>Thermal Systems Engineer</a>
          </Typography>

          {/* Filters */}
          {/* <Box display="flex" alignItems="flex-end" justifyContent="space-evenly" gap={2} mb={3}>
      <Autocomplete 
        options={jobTypes}
        value={jobType}
        onChange={(event, newValue) => setJobType(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Job Type"
            variant="standard"
          />
        )}
        sx={{ width: 200 }}
      />
      <Autocomplete
        options={workspaces}
        value={workspace}
        onChange={(event, newValue) => setWorkspace(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Workspace"
            variant="standard"
          />
        )}
        sx={{ width: 200 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        sx={{ height: "fit-content" }}
      >
        Search Jobs
      </Button>
    </Box> */}

          {/* Job Listings */}
          {/* <Typography variant="subtitle1" gutterBottom>
      Number of jobs found: {jobData.length}
    </Typography>
    <Grid container spacing={2}>
      {jobData.map((job, index) => (
        <Grid item xs={12} key={index}>
          <Card
            elevation={3}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="h6" component="div">
                {job.title}
              </Typography>
              <Typography color="text.secondary">{job.location}</Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => handleViewJob(job)}
              >
                View Job
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid> */}

          {/* Job Description Dialog */}
          {/* {selectedJob && (
      <Dialog open={Boolean(selectedJob)} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{selectedJob.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {selectedJob.description}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Location: {selectedJob.location}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )} */}
        </Container>
      </div>
    </div>
  );
};

export default Career;
