import React, { useState, useRef, useEffect } from "react";
import "./Productnew.css"; // Assuming the CSS is moved to App.css or a similar file
import Order from "./Order"; // Import the Order component

const ProductBox = () => {
  const [showDetails, setShowDetails] = useState(false);
  const detailsRef = useRef(null); // Create a reference

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    if (showDetails && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showDetails]);

  return (
    <>
      <div className="containernew">
        <div
          className="box"
          style={{
            backgroundImage: `url("/productimg/Yotuh Sheen Frozen system.jpg")`,
            backgroundSize: "cover", // Ensures the image covers the entire background
            backgroundPosition: "center", // Centers the background image
            width: "400px", // Adjust width as needed
            height: "100%", // Adjust height as needed
            position: "relative", // Ensure positioning context for absolute overlay
          }}
        >
          <div className="overlay"></div> {/* Overlay div */}
          <div className="product__img">
            {/* Optionally include an <img> tag here if needed */}
          </div>
          <div className="product__disc">
            <div className="product__disc--content">
              <div className="disc__content--about">
                <h1>SHEEN 2.0</h1>
                <span>100% Electric Refrigeration Unit</span>
                <div>
                  {/* <p>
                    Temperature range - +25 to -18 deg. C
                    <br />
                    Charging time ~ 3.5 hrs
                    <br />
                    Refrigeration run time:
                    <br />
                    ~ 16-18 hrs for 0 deg C
                    <br />~ 7-9 hrs for -18 deg C
                  </p> */}
                  <p style={{ textAlign: "left", marginLeft: "10%" }}>
                    <li>
                      Uses 0% fuel - saves 75% of the running cost
                      <span></span>
                    </li>
                    {/* <li>Refrigeration Time (Full charge):<span> 10-12 hrs</span> </li> */}
                    <li>
                      No effect on vehicle mileage or life
                      <span></span>
                    </li>
                    <li>
                      Less maintenance cost
                      <span></span>
                    </li>
                    <li>
                      Can be used with 5ft to 8ft container size
                      <span></span>
                    </li>
                  </p>
                </div>
              </div>

              <div
                className="product__view--more"
                onClick={handleToggleDetails}
              >
                <p>view all specifications</p>
                <div className="view__more--block"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDetails && (
        <div class="product-info" ref={detailsRef}>
          <h1>100% Electric AC Unit With Stand-by</h1>
          <div class="feature-cards">
            <div class="feature-cardbronze1">
              <div class="feature-card-header" style={{color:"#106790"}}>Sheen 2.0</div>
              {/* <div class="feature-card-price">$12.99/month</div> */}
              <ul class="feature-card-features">
                {/* <li>
                  Used 0% fuel - saves 75% of the running cost
                  <span></span>
                </li>

                <li>
                  No effect on vehicle mileage or life
                  <span></span>
                </li>
                <li>
                  Less maintenance cost
                  <span></span>
                </li>
                <li>
                  Can be used with 5ft to 8ft container size
                  <span></span>
                </li> */}
                <p className="specification" style={{ fontSize: "20px" }}>
                  Temperature range - <span>+25 to -18 deg. C</span>
                  <br />
                  Charging time ~ <span>3.5 hrs</span>
                  <br />
                  Refrigeration run time:
                  <br />
                  ~ <span>14-16 hrs for 0 deg C</span>
                  <br />~ <span>7-9 hrs for -18 deg C</span>
                </p>
                {/* <li>Vehicle payload (recommended): <span>~600 kg</span> </li> */}
              </ul>
              {/* <button class="feature-card-button">View Plans & Prices</button> */}
            </div>
          </div>
        </div>
      )}
      {/* <div className="feature-parent" style={{ margin: "auto", width: "100%" }}>
        <h2 style={{ textAlign: "center", fontSize: "50px" }}>Features</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100vw",
          }}
        >
          <div style={{ padding: "20px" }}>
            <ul>
              <li>100% Electric Active Refrigeration</li>
              <hr />
              <li>Electric stand-by option</li>
            </ul>
          </div>
          <div style={{ padding: "20px" }}>
            <ul>
              <li>Live Monitoring of Preventive Measure</li>
              <hr />
              <li>Faster Cooling - Multi point delivery</li>
            </ul>
          </div>
        </div>
      </div> */}

      {/* <div>
        <div
          className="demo-section"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="demo-video-visual">
        
          </div>
          <div className="demo-section-wrapper">
            <div className="demo-content-wrapper">
              <div className="demo-content demo-content-1">
                <div className="demo-mobile-visual">
                  <img
                    className="demo-card-img"
                    src="/productsc3.png"
                    alt="vehicle"
                    style={{width:"100%"}}
                  />
                </div>
                <div className="demo-meta">
                  <h2 className="demo-headline">
                
                    <span className="demo-text-highlight"> Sustainability</span>
                  </h2>
                  <p className="demo-desc" style={{textAlign:"center"}}>
                    We are one of the first startups leading the energy
                    transition in the cold chain. With our solutions, we are not
                    just making the cold chain sustainable but more accessible
                    and affordable for even low economic value perishables.
                  </p>
                </div>
              </div>
              <div className="demo-content demo-content-2">
                <div className="demo-mobile-visual">
                  <img
                    className="demo-card-img"
                    src="productsc2.png"
                    alt="vehicle"
                  />
                </div>
                <div className="demo-meta">
                  <h2 className="demo-headline">
                    <span className="demo-text-highlight">Uniqueness</span>
                  </h2>
                  <p className="demo-desc" style={{textAlign:"center"}}>
                    Our product, Sheen2.0 is the first of its class 100%
                    electric AC unit with Standby power and adaptive cooling
                    controls. It can be mounted on IC Engine vehicles like Tata
                    ACE and it can also work with the commercial EVs. Mainly
                    targeting the rising intra-city perishable demand, we are
                    enabling a whole new market with Small Commercial Vehicles
                    and EVs to cater the sub-one tonne (less than 1 tonne)
                    demand of the quick commerce market.
                  </p>
                </div>
              </div>
            </div>
            <div className="demo-visual">
              <div className="demo-card-wrapper">
                <div className="demo-card demo-card-1">
                  <img
                    className="demo-card-img"
                    src="Productsc3.png"
                    alt="vehicle"
                  />
                </div>
                <div className="demo-card demo-card-2">
                  <img
                    className="demo-card-img"
                    src="productsc2.png"
                    alt="vehicle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <h2
        style={{
          textAlign: "center",
          padding: "20px",
          marginTop: "50px",
        }}
        className="conth2"
      >
        Our Products
      </h2>
      <div className="product-paragraph">
        <p style={{ padding: "4%",paddingTop:"1%", textAlign: "justify" }} className="page1para">
          We are one of the first startups leading the energy transition in the
          cold chain. With our solutions, we are not just making the cold chain
          sustainable but more accessible and affordable for even low economic
          value perishable goods.
          <br />
          <br />
          Our product, Sheen2.0 is the first of its class 100% electric AC unit
          with Standby power and Adaptive Cooling Controls. It can be mounted on
          IC Engine vehicles like Tata ACE and it can also work with the
          commercial EVs. Mainly targeting the rising intra-city perishable
          demand, we are enabling a whole new market with Small Commercial
          Vehicles and EVs to cater the sub-one tonne ({"<"}1 tonne) demand
          of the quick commerce market.
        </p>
        <h3 style={{ textAlign: "center" }}>
          Take a look at the vehicles enabled by our solution
        </h3>
        <div className="names">
          <h3 style={{ textAlign: "center" }}>
            Tata Ace Diesel + Yotuh’s SHEEN2.0
          </h3>
          <h3 className="h3two" style={{ textAlign: "center" }}>
            ACE EV + Yotuh’s SHEEN2.0
          </h3>
        </div>
      </div>

      <div className="classforimg">
        <div
          className="product-new-image-container"
          style={{ margin: "5%", marginRight: "1%",marginTop: "0"}}
        >
          <img
            src="/productimg/Untitleddesign.jpg"
            alt="Avatar"
            className="image"
            style={{ borderRadius: "10px" }}
          />
          <div className="overlay">
            {/* <div className="icon" title="User Profile">
              Hello
            </div> */}
            <div class="product-infoov">
              <h1>
                Cater to the rising quick commerce (sub-one tonnes) perishable
                delivery market
              </h1>
              <div class="feature-cardsov">
                <div class="feature-card bronzeov">
                  <div class="feature-card-headerov">
                    Running cost ~ Rs. 6/km
                  </div>
                  <ul class="feature-card-featuresov">
                    <li>
                      Temperature Range: <span> +25 deg C to -18 Deg C</span>
                    </li>
                    <li>
                      AC charging time:<span> 3.5 Hrs (to full charge)</span>{" "}
                    </li>
                    <li>
                      Vehicle Range: <span> NA</span>
                    </li>

                    <li>
                      Vehicle Payload <span>~ 500 Kgs</span>
                    </li>
                    <li>
                      No effect on Vehicle Mileage & life <span></span>
                    </li>
                    <li>
                      Lower maintenance cost
                      <span></span>{" "}
                    </li>
                  </ul>
                  {/* <button class="feature-card-button">View Plans & Prices</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h3 className="h3twonew">ACE EV + Yotuh’s SHEEN2.0</h3> */}

        <div
          className="product-new-image-container"
          style={{ marginRight: "5%", marginLeft: "1%" }}
        >
          <img
            src="/productimg/productc.jpg"
            alt="Avatar"
            className="image"
            style={{ borderRadius: "10px" }}
          />
          <div className="overlay">
            <div class="product-infoov">
              <h1>
                First of Its kind 100% Electric Cold chain Active refrigerated
                vehicle{" "}
              </h1>
              <div class="feature-cardsov">
                <div class="feature-card bronzeov">
                  <div class="feature-card-headerov">
                    Running cost ~ Rs. 2.5/km
                  </div>
                  <ul class="feature-card-featuresov">
                    <li>
                      Temperature Range: <span>+25 deg C to -18 Deg C</span>
                    </li>
                    <li>
                      AC charging time:<span> 3.5 Hrs (to full charge)</span>{" "}
                    </li>

                    <li>
                      Vehicle Range: <span>110 Kms</span>
                    </li>
                    <li>
                      Vehicle Charging time : <span>~7 Hrs</span>
                    </li>
                    <li>
                      Vehicle Payload: <span>~600 Kgs</span>
                    </li>
                    <li>
                      Lower maintenance cost<span></span>{" "}
                    </li>
                  </ul>
                  {/* <button class="feature-card-button">View Plans & Prices</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="parapdt" style={{ padding: "5%",paddingTop:"2%", fontSize: "20px" }}>
        <h3 style={{ textAlign: "center" }}>
          Leading the energy transition in cold chain
        </h3>
        <div className="makeinindia">
          <img src="/sher.png" alt="Avatar" className="makeinindia-image" />
          <div className="vertical-line"></div>
          <div className="maketext-container">
            <h3>MAKE IN INDIA</h3>
            <h3>MAKE FOR WORLD</h3>
          </div>
        </div>
        <p>
        We are bringing the future of cold chain transport refrigeration, designed, developed, and made in India. While we are diligently working to introduce this innovation into the market, our early adopters are instrumental in helping us refine it to the highest standards. Through on-road pilots, we are continuously improving the product to achieve best-in-class efficiency and deliver exceptional results.{" "}
        </p>
      </div>

      <h2 style={{ textAlign: "center" }} className="pilothead">PILOTS DONE WITH</h2>

      <div className="pilots">
        <div className="itemsp">
          <a
            href="https://milkpot.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/cropped-cropped-Milkpot-Logo-01-2-1.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Milk Pot{" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/Mc_PNG-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            McRennett {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://www.bakersspring.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/bakerSpring.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Bakers Spring  {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://akshayakalpa.org/chennai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/Akshaykalpa organic logo.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Akshaykalpa  {" "}
          </h4>
        </div>
        <div className="itemsp">
          <a
            href="https://www.thekarikadai.com/artifacts/pages/kari-kadai/Kari-kadai-About_us.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/OmrKK.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            OMR Kari kadhai {" "}
          </h4>
        </div>
        <div className="itemsp">
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/AVN_Logistics.jpeg-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            AVN Logistics {" "}
          </h4>
        </div>
        <div className="itemsp">
          <a
            href="https://m.indiamart.com/hyretail-technologies-private-limited-faridabad/logistics-services.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/Taapmaan.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Taapmaan  {" "}
          </h4>
        </div>
        <div className="itemsp">
          <a
            href="https://vethaa.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/vethaa_Milk_logo.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Vethaa milk {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://www.zomato.com/chennai/adyar-bakery-adyar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/Adyar_Bakery.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Adyar Bakery {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://coagro.in/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/CO_agro_logo.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Co Agro {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://indiskafarms.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/indiska_logo_180x.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Indiska Farms {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://www.parkelanza.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/park-elanza-chennai-logo.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "15px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Park Elazna {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://dilfoods.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/Dil-Foods-new-logo.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Dil foods {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://siphappens.co.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/SiP_Happens_logo-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Sip Happens {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://fruvanto.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/fruvanto_logo.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "20px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Fruvanto{" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://www.zomato.com/chennai/the-cake-point-anna-nagar-east"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/theCakePoint-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            The Cake Point {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://www.indiamart.com/launchpad-consumer-products-llp/?srsltid=AfmBOooTqnvOlRrHRCWuSG04UXMhPMGJcf5UFWbJzCFBAzQ_VnBcy7TL"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/launchpad-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            LaunchPad{" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://www.facebook.com/VallabhaMilkProducts/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/vallabha-removebg-preview.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "0px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            Vallabha {" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href=" https://nrcagro.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/nrc.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "5px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            NRC Agro{" "}
          </h4>
        </div>

        <div className="itemsp">
          <a
            href="https://rkfoodland.com/ "
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="piloti"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src="/pilots/RadhakrishnaFL.png"
                className="sponsorslogo"
                alt="logo"
                style={{
                  height: "100px",
                  alignitemsp: "center",
                  padding: "20px",
                }}
              />
            </div>
          </a>
          <h4
            style={{ textAlign: "center", minWidth: "200px", marginTop: "5%" }}
          >
            {" "}
            RK Foodland {" "}
          </h4>
        </div>

      </div>

      
      <div className="parapdt2">
        <p style={{ textAlign: "justify"}}>
          We are actively looking for the pilot partners and some eager
          customers who are willing to do the pilots with our technology and
          become one of the early adopters.
        </p>
      </div>
      <h2 style={{ textAlign: "center" }} className="pilotbot">Contact Us on Details provided below</h2>


      <Order /> 
    </>
  );
};

export default ProductBox;
