import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import "./jobroleinfo.css";
const RoleMechE = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{ padding: "5%" }}>
      <div className="rolecontainer mx-auto p-8 rounded-lg max-w-3xl">
        <a
          href="/jobs"
          className="text-gray-600 hover:text-orange-600 transition-colors duration-300 mb-4 block"
        >
          &lt; Back
        </a>

        <div className="mb-8">
          <Typography
            variant="h4"
            component="h1"
            color="textPrimary"
            fontWeight="bold"
          >
            Mechanical Engineer
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Chennai, Tamil Nadu, India
          </Typography>
        </div>

        <div className="typebox">
          <Box display="flex" justifyContent="space-between" mb={4}>
            <div className="custom-container">
              <Typography variant="body1" align="left" color="textPrimary">
                <strong>Job Type:</strong> Full Time
              </Typography>

              <Typography variant="body1" align="left" color="textPrimary">
                <strong>Availability:</strong> Immediate
              </Typography>
            </div>
          </Box>
        </div>

        <section className="mb-12 text-left">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            About the Company:
          </Typography>
          <Typography
            variant="body1"
            align="justify"
            className="mt-4 text-justify"
            sx={{ marginBottom: 2 }}
          >
            Yotuh Energy's mission toward sustainable and ultra energy-efficient
            cooling has just begun, starting with the often-overlooked
            yet deeply impactful sector of cold chain logistics.
            <br />
            <br />
            We engineer electric refrigeration units for cold logistics trucks.
            Our product ensures the correct temperature is maintained in the
            cargo container of truck during the transit. By doing so we provide
            more economical, reliable and greener way to transport perishables
            like milk, curd, paneer, meat, seafood, icecreams, frozen foods,
            fruits veggies, as well as vaccines and pharmaceuticals.With such a
            massive population to feed, our product solves for big missing link
            in cold chain logistics.
          </Typography>
        </section>

        <section className="mb-12 text-left">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            About the Role:
          </Typography>
          <Typography
            variant="body1"
            align="justify"
            className="mt-4 text-justify"
            sx={{ marginBottom: 2 }}
          >
            Yotuh Energy is the first and only company in India to build
            electric refrigeration units that are enabling upcoming Electric
            Vehicles and existing Small Commercial Vehicles to be able to do
            cold logistics.
            <br />
            <br />
            As Mechanical Design Engineer under Chassis System responsible for:
            <br />
            <br />
            <ul
              className="list-disc list-inside mt-4 text-lg "
              style={{ listStyleType: "disk" }}
            >
              <Typography variant="body1" align="justify" mb={4} ml={4}>
                <li>
                  Design of various components associated with Frames,
                  instrument cluster, body panels, electronics and charger
                  housings, Testbenches, etc.
                </li>
                <li>
                  Work along with other departments to optimize for efficient
                  packaging and performance, ensuring manufacturability,
                  durability, and BOM are intact.
                </li>
                <li>
                  Planning System Level engineering goals, timelines, and
                  executing on it.
                </li>
              </Typography>
            </ul>
            Role offers a very unique and tough enduring opportunity for
            engineers to design, build, test, and scale up a unique product.
            Building a refrigeration unit that is carrying critical products
            giving no time till they will perish- demands to have one of the
            most reliable electronics ever built.
          </Typography>
        </section>

        <section className="mb-12 text-left">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            mt={4}
            sx={{ marginBottom: 2 }}
          >
            Responsibilities:
          </Typography>
          <ul className="list-disc list-inside mt-4 text-lg " style={{ listStyleType: "disk" }}>
            <Typography variant="body1" align="justify" mb={4} ml={2}>
              <li>
                Design and Develop Structural and Body components like Frames,
                Instrument Cluster, BodyPanels, Electronics and Charger Housing,
                etc.
              </li>
              <li>
                Design components for best performance, efficient packaging,
                durability, serviceability, scale up manufacturabiltiy, etc.
              </li>
              <li>Develop various prototypes and validate various DFXs.</li>
              <li>Plan and perform different testing under DVPs.</li>
              <li>
                Own the low volume manufacturing, dealing with the vendors,
                directing assemblies, designing required jigs&fixtures and final
                inspections.
              </li>
              <li>
                Own and lead the transition of taking design to scalable
                production stages.
              </li>
              <li>
                Bring new improvements in process, design & production to be
                compliant with scale-up requirements.{" "}
              </li>
            </Typography>
          </ul>
        </section>

        <section className="mb-12">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            Requirements:
          </Typography>
          <ul className="list-disc list-inside mt-4 text-lg "style={{ listStyleType: "disk" }}>
            <Typography variant="body1" align="justify" mb={4} ml={2}>
              <li>
                Strong understanding of Strength of Materials, Structures and
                Theory of Machines.
              </li>
              <li>
                Knowledge of using FEA to simulate and analyse how the design
                will react to real-world factors.
              </li>
              <li>
                Proficiency in CAD software (SolidWorks, CATIA, etc.) and other
                relevant design tools.
              </li>
              <li>
                Experience with DFMEA (Design Failure Mode and Effects Analysis)
                in product development.
              </li>
              <li>Hands-on experience with surface modeling is a plus.</li>
              <li>Knowledge in Geometric Dimensioning & Tolerances.</li>
              <li>
                Knowledge in material properties & accurate choice of
                appropriate materials.{" "}
              </li>
            </Typography>
          </ul>
        </section>

        <section className="mb-12">
          <Typography
            variant="h4"
            component="h2"
            color="textPrimary"
            fontWeight="semibold"
            sx={{ marginBottom: 2 }}
          >
            Hiring Process:
          </Typography>
          <Typography variant="body1" align="justify">
            If you have been shortlisted, you will receive confirmation mail
            within 3 days of application. There will be a Technical Interview
            and followed another Founder’s Interview. There could be engineering
            challenge to be submitted.
          </Typography>
        </section>
        <div>
          <a href="/jobForm">
            <Button
              variant="contained"
              color="warning"
              sx={{
                paddingX: 4,
                paddingY: 2,
                borderRadius: 2,
                fontSize: "1rem",
                textTransform: "none",
                marginTop: 5,
              }}
            >
              Apply Now
            </Button>
          </a>
        </div>
      </div>
    </Container>
  );
};

export default RoleMechE;
