import React from 'react'
import { Typography, Container } from "@mui/material";
const Culture = () => {
  return (
    <div>
     <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{ padding: "5%" }}>
      <Typography
        variant="h3"
        align="center"
        color="primary"
        sx={{
          fontWeight: "bold",
          fontSize: "2.5rem",
          marginBottom: "2rem",
        }}
      >
        Culture
      </Typography>
      
      <Typography
        variant="body1"
        align="justify"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.7",
          marginBottom: "2rem",
        }}
      >
        Building in an early-stage startup is an immensely challenging yet rewarding journey. <br/>Being present at the epicenter of a change—right where the future is being shaped.
      </Typography>

      <Typography
        variant="body1"
        align="justify"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.7",
          marginBottom: "2rem",
        }}
      >
        Turning the improbable into reality takes a lot of hustle, dedication, and determination towards the goal. The key to success lies in surrounding & resonating with people who share common values—because that’s how collaborative decision-making and swift innovation happen.
      </Typography>

      <Typography
        align="justify"
        sx={{
          fontSize: "1.2rem",
          marginBottom: "2rem",
        }}
      >
        As we grow Bigger & Better—our culture may evolve, but the core essence will always be grounded in these principles:
      </Typography>

      <Typography
        variant="h5"
        align="left"
        sx={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        Love for Building Stuff
      </Typography>
      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.6",
          marginBottom: "2rem",
        }}
      >
        We’re driven by the joy of building things from scratch. It is extremely satisfying as an engineer to witness the creation in action.
      </Typography>

      <Typography
        variant="h5"
        align="left"
        sx={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        Own the Work
      </Typography>
      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.6",
          marginBottom: "2rem",
        }}
      >
        We take full ownership of everything we do. From the process to the outcome, we own it all. It’s about accountability, growth, and always striving to do our best.
      </Typography>

      <Typography
        variant="h5"
        align="left"
        sx={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        Swift Action
      </Typography>
      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.6",
          marginBottom: "2rem",
        }}
      >
        Speed is critical. We value execution without hesitation—no redundancies, no delays. The faster we move, the more we learn and improve.
      </Typography>

      <Typography
        variant="h5"
        align="left"
        sx={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        Bold, Ambitious Goals
      </Typography>
      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.6",
          marginBottom: "2rem",
        }}
      >
        We set ambitious, almost scary targets. Because if we’re not aiming high, we’re not pushing ourselves hard enough to truly innovate.
      </Typography>

      <Typography
        variant="h5"
        align="left"
        sx={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        Resilience in Action
      </Typography>
      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "1.2rem",
          lineHeight: "1.6",
          marginBottom: "2rem",
        }}
      >
        Failure is part of the journey. What matters is our ability to bounce back stronger, with a better strategy and renewed energy.
      </Typography>
      </Container >
    </div>
  )
}

export default Culture