import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";

const PrinciplesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
`;

const PrincipleItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;
`;

const PrincipleImage = styled.img`
  width: 70px;
  height: 70px;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const PrincipleText = styled.div``;

const PrincipleSection = () => {
  return (
    <PrinciplesContainer>
      {principles.map((principle, index) => (
        <PrincipleItem key={index}>
          <PrincipleImage src={principle.image} alt={principle.title} />
          <PrincipleText>
            <Typography
              variant="h5"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                marginBottom: "0.5rem",
              }}
            >
              {principle.title}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: "0.95rem",
                lineHeight: "1.5",
                textAlign: "justify",
              }}
            >
              {principle.description}
            </Typography>
          </PrincipleText>
        </PrincipleItem>
      ))}
    </PrinciplesContainer>
  );
};

const principles = [
  {
    image: "/careerImg/LoveforBuildingStuff.png",
    title: "Love for Building Stuff",
    description:
      "We’re driven by the joy of building things from scratch. It is extremely satisfying as an engineer to witness the creation in action.",
  },
  {
    image: "/careerImg/OwntheWork.png",
    title: "Own the Work",
    description:
      "We take full ownership of everything we do. From the process to the outcome, we own it all. It’s about accountability, growth, and always striving to do our best.",
  },
  {
    image: "/careerImg/SwiftAction.png",
    title: "Swift Action",
    description:
      "Speed is critical. We value execution without hesitation—no redundancies, no delays. The faster we move, the more we learn and improve.",
  },
  {
    image: "/careerImg/Bold,AmbitiousGoals.png",
    title: "Bold, Ambitious Goals",
    description:
      "We set ambitious, almost scary targets. Because if we’re not aiming high, we’re not pushing ourselves hard enough to truly innovate.",
  },
  {
    image: "/careerImg/Resilience_in_Action.png",
    title: "Resilience in Action",
    description:
      "Failure is part of the journey. What matters is our ability to bounce back stronger, with a better strategy and renewed energy.",
  },
];

export default PrincipleSection;
